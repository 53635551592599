/* PopMenu */
@use '../../css/settings.scss' as pd;

.popmenu {
  position: relative;
  visibility: hidden;
  &.popmenu_active .popmenu_items + .toolhint {
    opacity: 0;
  }
  &.popmenu_noposition {
    position: unset;
  }
}
*:hover > .popmenu {
  visibility: visible;
  .popmenu_items {
    height: auto;
  }
}
.popmenu_items {
  background: pd.color(bg, popmenu);
  color: pd.color(text, cards);
  font-size: pd.$font_size;
  border-radius: pd.$lg;
  border: 1px solid pd.color(border, popmenu);
  position: absolute;
  top: -12px;
  right: -8px;
  box-shadow: pd.$box_shadow;
  white-space: nowrap;
  padding: pd.$sm pd.$md;
  height: 0; // important, so the menu does not occupy space when hidden
  // overflow: visible; // if set to auto or scroll, this shows up as a bug in Safari when visibility: hidden
  z-index: 999;
  cursor: pointer;
  display: flex;
  gap: pd.$sm;
}
.popmenu.popmenu_vertical .popmenu_items {
  flex-direction: column;
}
