/* SettingSelect */
@use '../../css/settings.scss' as pd;

.select {
  display: flex;
  gap: pd.$sm;
}
.dropmenu_items > .select:hover {
  text-decoration: none;
}
